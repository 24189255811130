<template>
  <v-row align="start" no-gutter dense>
    <v-col cols="3" class="primary--text text-caption mt-2">
      <slot />
    </v-col>
    <v-col :cols="width">
      <v-text-field
        :autocomplete="autocomplete"
        dense
        flat
        outlined
        :append-icon="type === 'password' ? showPassword ? mdiEye : mdiEyeOff : ''"
        :counter="counter"
        :disabled="disabled"
        :hide-details="hideDetails"
        :hint="hint"
        :persistent-hint="persistentHint"
        :readonly="readonly"
        :rules="getRules"
        :type="type === 'password' ? showPassword ? 'text' : 'password' : type"
        :value="value || ''"
        @keydown.space = "processSpace"
        @input="input"
        @change="change"
        @click:append="showPassword = !showPassword"
      />
    </v-col>
    <slot name="append" />
  </v-row>
</template>

<script>
import { mdiEye, mdiEyeOff } from "@mdi/js"

export default {
  props: {
    autocomplete: {
      type: String,
      default: 'new-password'
    },
    counter: {
      type: [Number, String],
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    },
    field: {
      type: String,
      default: ''
    },
    hint: {
      type: String,
      default: ''
    },
    min: {
      type: [Number, String],
      default: 3
    },
    persistentHint: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    },
    hideDetails: {
      type: [String, Boolean],
      default: true
    },
    rules: {
      type: Array,
      default () { return [] }
    },
    type: {
      type: String,
      default: ''
    },
    width: {
      type: [Number, String],
      default: 9
    },
    value: {
      type: [Number, String]
    }
  },
  data () {
    return {
      showPassword: false,
      validations: {
        email: value => (/[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/.test(value)) || 'Not a valid email',
        required: value => !!value || 'Required.',
        min: value => value.length >= this.min || `Minimum ${this.min} characters`,
        matchPassword: value => {
          return (value === this.user.password) || (`The passwords you entered do not match`)
        }
      },
      mdiEye,
      mdiEyeOff
    }
  },
  computed: {
    getRules () {
      return this.rules.map(r => this.validations[r])
    }
  },
  methods: {
    change(value) {
      const field = this.field
      this.$emit('change', { field, value })
    },
    input(value) {
      const field = this.field
      this.$emit('input', { field, value })
    },
    processSpace(e) {
      if(this.type === 'password') {
        e.preventDefault()
      }
    }
  }
}
</script>
