<template>
  <v-row :align="align || 'start'" no-gutter dense class="">
    <v-col cols="3" :class="`primary--text text-caption mt-${labelMarginTop}`">
      {{ label }}
    </v-col>
    <v-col cols="9">
      <slot />
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: ''
    },
    align: {
      type: String,
      default: null
    },
    labelMarginTop: {
      type: [String, Number],
      default: 2
    }
  }
}
</script>
