<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <DataTable
          :cols="cols"
          :data="items"
          :rowColorResolver="rowColorResolver"
          @selectOne="select"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col class="pl-4">
        <v-btn
          color="success"
          depressed
          :ripple="false"
          @click="$emit('add', { type })"
        >
          Add New User
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { useFind } from 'feathers-vuex'
import { computed } from '@vue/composition-api'

import DataTable from '@/components/UI/data-table'

// import AddUserForm from './add-user-form'

export default {
  props: {
    cols: {
      type: Array,
      default() { return [] }
    },
    type: {
      type: String,
      default: ''
    }
  },
  components: {
    DataTable,
    // AddUserForm
  },
  setup(props, context) {
    const { User } = context.root.$FeathersVuex.api
    const { emit } = context

    const params = computed(() => {
      return {
        query: {
          type: props.type,
          $sort: { enabled: -1, name: 1 },
          $limit: 99
        },
      }
    })

    const { items  } = useFind({ model: User, params })

    const select = item => { emit('select', item) }

    const rowColorResolver = item => {
      return item.enabled ? '' : '#fdd'
    }

    return {
      items,
      rowColorResolver,
      select
    }
  }
}
</script>
