<template>
  <v-container
    v-cloak
    class="page-with-background fill-height"
    style="align-content:start"
    fluid
    @drop.prevent="addFile"
    @dragover.prevent
  >
    <v-row justify="center" align="start">
      <v-col cols="auto">
        <div class="my-10">
          <Logo :width="300" class="my-4" />
          <div
            class="text-h5 accent--text text-center"
          >
            {{ appName }}
          </div>
        </div>
      </v-col>
    </v-row>

    <v-row justify="center" align="start">
      <v-col
        v-for="(section, key) in sections"
        :key="key"
        cols="auto"
        class="mx-6"
        style="width:240px"
      >
        <div :class="sectionLabelClass(section, 'text-subtitle-1 mb-1 pl-3')">
          <b>{{ key }}</b>
        </div>
        <v-divider />

        <v-list style="background:transparent">
          <v-list-item-group>
            <v-list-item
              v-for="(item, j) in section"
              :key="j"
              dense
              :to="{ path: item.path }"
              :ripple="false"
              :disabled="item.meta.menu.disabled"
            >
              <v-list-item-icon class="ml-n2 mr-3">
                <v-icon
                  color="primary"
                  :disabled="item.meta.menu.disabled"
                >
                  {{ item.meta.menu.icon }}
                </v-icon>
              </v-list-item-icon>
              <v-list-item-title>
                {{ item.meta.menu.label }}
              </v-list-item-title>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-col>
    </v-row>
  </v-container>
</template>

<style>
.page-with-background {
  background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IiB2aWV3Qm94PSIwIDAgMTkyMCAxNDMwIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAxOTIwIDE0MzA7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4gPHN0eWxlIHR5cGU9InRleHQvY3NzIj4gLnN0MXtmaWxsOiNERUVGRjk7fSAuc3Qye2ZpbGw6I0VFRjdGQzt9IDwvc3R5bGU+IDxnIGlkPSJMYXllcl8xIj4gPHBvbHlnb24gY2xhc3M9InN0MiIgcG9pbnRzPSIxLDE4My4zIDI0My44LDI4Ny4xIDEsMzYwICAiLz4gPHBvbHlnb24gY2xhc3M9InN0MiIgcG9pbnRzPSIxMDA4LjYsMjQwIDEyMjQuNSwyMTQgMTMxNy40LDI4MS45ICAiLz4gPHBvbHlnb24gY2xhc3M9InN0MiIgcG9pbnRzPSIxNTQ0LjIsMzEyLjcgMTkyMSwxODMuMyAxOTIxLDMxNCAxODE2LjYsMzQ3LjggICIvPiA8cG9seWdvbiBjbGFzcz0ic3QxIiBwb2ludHM9IjAsMzYwIDU4MiwxODAgMTgxMywzNDMgMTkyMSwzMTMgMTkyMSwxNDMwIDAsMTQzMCAgIi8+IDwvZz4gPC9zdmc+');
  background-color: #fff;
  background-position: 0 60vh;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
}
</style>

<script>
import Logo from '@/components/Logo.vue'
import { getMenuItems } from '@/router/oc'

export default {
  components: {
    Logo
  },
  computed: {
    appName() {
      return this.$store.state.appName
    },
    sectionLabelClass() {
      return (section, classes) => ['primary--text', classes].join(' ')
    },
    sections() {
      return getMenuItems()
    }
  }
}
</script>
