<template>
  <div v-if="isChanging">
    <FormGroupInput
      field="password"
      hide-details="auto"
      persistent-hint
      type="password"
      width="5"
      :counter="min"
      :hint="hint"
      :rules="['required', 'min']"
      :value="password"
      @input="change"
    >
      {{ isChanging && !newUser ? 'New Password' : 'Password'}}
      <template
        v-slot:append
        v-if="password"
      >
        <div class="append ml-2">
          <v-chip
            label
            class="text-caption font-weight-black"
            :color="strengths[score].color"
          >
            {{ strengths[score].label }}
          </v-chip>
        </div>
      </template>
    </FormGroupInput>
    <FormGroupInput
      field="confirm"
      hide-details="auto"
      type="password"
      width="5"
      :_rules="[matchPassword]"
      :value="confirm"
      :counter="min"
      @input="change"
    >
      Confirm Password
    </FormGroupInput>

    <FormGroupLabelledComponent
      v-if="isChanging && !newUser"
      label=""
    >
      <v-btn
        class="mr-3"
        color=""
        depressed
        @click="changing = false"
      >cancel</v-btn>
      <v-btn
        color="primary"
        depressed
        :disabled="!canSubmit"
        @click="update"
      >update</v-btn>
    </FormGroupLabelledComponent>

    <!-- <FormGroupButton

      label="update"
      @click="update"
    /> -->
    <PasswordMeter
      v-if="password"
      v-show="false"
      :password="password"
      @score="onScore"
    />
  </div>
  <FormGroupButton
    v-else
    label="Change"
    @click="changing = true"
  >
    Password
  </FormGroupButton>
</template>

<style scoped>
.append { margin-top: 6px }
</style>

<script>
import PasswordMeter from "vue-simple-password-meter";

import FormGroupButton from '@/components/UI/forms/form-group/button'
import FormGroupLabelledComponent from '@/components/UI/forms/form-group/labelled-component'
import FormGroupInput from '@/components/UI/forms/form-group/input'

const min = 3
const hint = `Minimum ${min} characters`

export default {
  props: {
    newUser: {
      type: Boolean,
      default: false
    }
  },
  components: {
    FormGroupButton,
    FormGroupLabelledComponent,
    FormGroupInput,
    PasswordMeter
  },
  data () {
    return {
      changing: false,
      confirm: '',
      min,
      hint,
      password: '',
      // rules: {
      //   required: value => !!value || 'Required.',
      //   min: value => value.length >= min || hint,
      //
      // },
      strengths: [
        {
          color: 'red lighten-2',
          label: 'VERY WEAK'
        },
        {
          color: 'orange darken-1',
          label: 'WEAK'
        },
        {
          color: 'yellow darken-1',
          label: 'GOOD'
        },
        {
          color: 'lime',
          label: 'STRONG'
        },
        {
          color: 'light-green accent-4',
          label: 'VERY STRONG'
        }
      ],
      score: 0
    }
  },
  computed: {
    isChanging () {
      return this.newUser || this.changing
    },
    canSubmit () {
      const p = this.password.trim()
      const c = this.confirm.trim()
      return p && c && p == c
    }
  },
  methods: {
    change({ field, value }) {
      if (field === 'password') {
        this.password = value.trim()
      } else {
        this.confirm = value.trim()
      }
      if (this.newUser) {
        // tell parent
        if (this.confirm === this.password) {
          this.$emit('change', { field: 'password', value: this.password })
        } else {
          this.$emit('change', { field: 'password', value: null })
        }
      }
    },
    matchPassword: value => {
      return (value === this.password) || (`The passwords you entered do not match`)
    },
    update() {
      this.$emit('update', { field: 'password', value: this.password })
      this.changing = false
    },
    onScore({ score }) {
      this.score = score // from 0 to 4
    }
  },
  watch: {
    changing (newVal, oldVal) {
      if (!oldVal && newVal) {
        this.password = ''
        this.confirm = ''
      }
    }
  }
}
</script>
