<template>
  <v-row
    align="center"
    dense
    no-gutters
  >
    <PageSubtitle
      :icon="false"
      title="Rejected Findings"
    />

    <v-col cols="12" class="mb-3">
      <List
        v-if="items.length"
        :items="items"
        @view="view"
      />
      <Message v-else />
    </v-col>
    <DocumentDrawer
      hide-print
      :open="ddo"
      :title="ddt"
      :width="960"
      :stateless="false"
      @close="ddo=false"
    >
      <SingleView
        v-if="selected._id"
        :id="selected._id"
      />
    </DocumentDrawer>
  </v-row>
</template>

<script>
import { useFind } from 'feathers-vuex'
import { computed, ref } from '@vue/composition-api'

import DocumentDrawer from '@/components/UI/document-drawer'
import PageSubtitle from '@/components/UI/page-subtitle'

import Message from './message'
import List from './list'
import SingleView from './single-view-wrapper'

export default {
  components: {
    DocumentDrawer,
    PageSubtitle,
    List,
    Message,
    SingleView
  },
  setup(props, context) {
    const { Rejected } = context.root.$FeathersVuex.api

    const selected = ref({})
    const ddo = ref(false)
    const ddt = computed(() => {
      if (!selected.value.finding) return ''
      return selected.value.finding.acbFindingFile
    })
    const view = ( item ) => {
      selected.value = item
      ddo.value = true
    }

    const params = computed(() => {
      return { query: { $sort: { '_id': -1 } } }
    })
    const fetchParams = computed(() => {
      return {
        query: {
          $select: [
            '_id',
            'finding.acbFindingFile',
            'metadata.rejected.user',
            'metadata.rejected.date',
            'metadata.rejected.reason'
          ],
          $sort: { '_id': -1 },
          $limit: 99
        },
      }
    })
    const { items } = useFind({
      model: Rejected,
      params,
      fetchParams
    })
    return {
      ddo,
      ddt,
      items,
      selected,
      view
    }
  }
}
</script>
