<template>
  <v-row align="center" no-gutter dense>
    <v-col cols="3" class="primary--text text-caption">
      <slot />
    </v-col>
    <v-col cols="9">
      <Select
        :items="items"
        :field="field"
        :label="label"
        :value="value"
        :readonly="readonly"
        :required="required"
        @change="change"
      />
    </v-col>
  </v-row>
</template>

<script>
import Select from '../select'

export default {
  props: {
    disabled: { type: Boolean, default: false },
    field: { type: String, default: ''},
    label: { type: String, default: ''},
    items: { type: Array, default() { return [] } },
    readonly: { type: Boolean, default: false },
    required: { type: Boolean, default: false },
    value: { type: [String, Number] }
  },
  components: {
    Select
  },
  methods: {
    change ({ field, value }){
      this.$emit('change', { field, value })
    }
  }
}
</script>
