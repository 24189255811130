<template>
  <v-container fluid class="px-4 pb-16">
    <Confirm ref="confirm" />
    <Tabs class="">
      <template
        v-for="(tab, i) in tabs"
      >
        <Tab
          :key="i"
          :label="tab.label"
        >
          <TabPanel
            :type="tab.type"
            :cols="tab.cols"
            @add="add"
            @select="select"
          />
        </Tab>
      </template>
    </Tabs>
    <DocumentDrawer
      :open="ddo"
      :title="ddt"
      :width="640"
      :stateless="stateless"
      @close="ddoClose"
    >
      <template
        v-slot:actionMenu
      >
        <ActionMenu
          :items="actionMenuItems"
        />
      </template>
      <template v-slot:default>
        <Detail
          v-if="selected"
          :user="selected"
          @close="ddoClose"
        />
      </template>
    </DocumentDrawer>
  </v-container>
</template>

<script>
import {
  mdiAlert,
  mdiClose,
  mdiLock,
  mdiLockOpen,
} from "@mdi/js"

import ActionMenu from '@/components/UI/action-menu'
import DocumentDrawer from '@/components/UI/document-drawer'

import Detail from './detail'
import TabPanel from './tab-panel'

import { tabs } from './tab-definitions'

export default {
  components: {
    ActionMenu,
    Detail,
    DocumentDrawer,
    TabPanel
  },
  computed: {
    ddt () {
      return this.selected ? this.selected.name || 'New User' : 'New User'
    },
    actionMenuItems () {
      if (! (this.selected && this.selected._id) ) return null
      const en = this.selected.enabled
        ? {
            label: 'Disable Account',
            icon: mdiLock,
            action: this.lock
          }
        : {
            label: 'Enable Account',
            icon: mdiLockOpen,
            action: this.unlock
          }
      const del = {
        label: 'Delete User',
        icon: mdiClose,
        action: this.remove
      }
      return [en, '', del]
    }
  },
  methods: {
    add ({ type }) {
      const { User } = this.$FeathersVuex.api
      this.selected = new User({ type })
      this.ddo = true
    },
    ddoClose () {
      this.ddo = false
      this.selected = null
    },
    select (user) {
      this.selected = user
      this.ddo = true
    },
    lock () {
      this.$store.dispatch('users/patch', [this.selected._id, { enabled: false }])
    },
    async remove () {
      this.stateless = true
      const result = await this.$refs.confirm.open(
        'Delete this User?',
        'This cannot be undone.',
        {
          acceptColor: 'red darken-4',
          acceptLabel: 'Discard',
          cancelLabel: 'Keep',
          color: 'red darken-4',
          icon: mdiAlert,
          width: 410
        }
      )
      if (result) {
        await this.$store.dispatch("users/remove", this.selected._id) //remove user
        this.ddoClose()
      }
      this.stateless = false
    },
    unlock () {
      this.$store.dispatch('users/patch', [this.selected._id, { enabled: true }])
    },
  },
  data () {
    return {
      ddo: false,
      tabs: tabs,
      selected: null,
      stateless: false,
    }
  }
}
</script>
