<template>
  <v-container fluid class="px-4 pb-16">
    <PageTitle />
    <DataTable
      :cols="cols"
      :data="items"
      @selectOne="select"
    />
    <v-row>
      <v-col class="pl-4">
        <v-btn
          color="success"
          depressed
          :ripple="false"
          @click="add"
        >
          Add New Flag Administration
        </v-btn>
      </v-col>
    </v-row>
    <DocumentDrawer
      :open="ddo"
      :title="ddt"
      :width="640"
      :stateless="false"
      @close="ddo=false"
    >
      <template v-slot:default>
        <Detail
          v-if="selected"
          :item="selected"
          @close="ddo=false"
        />
      </template>
    </DocumentDrawer>
  </v-container>
</template>

<script>
import { mdiClose, mdiCheckBold } from "@mdi/js"

import { useFind } from 'feathers-vuex'
import { computed } from '@vue/composition-api'

import DocumentDrawer from '@/components/UI/document-drawer'

import Detail from './detail'

export default {
  components: { Detail, DocumentDrawer },
  data () {
    return {
      ddo: false,
      ddt: 'Flag Administration Details',
      cols: [
        {
          field: 'name',
          label: 'Name',
          width: '50%'
        },
        {
          align: 'center',
          field: 'enabled',
          label: 'Enabled',
          width: '1%',
          type: Boolean,
          icons: {
            false: {
              icon: mdiClose,
              color: 'error'
            },
            true: {
              icon: mdiCheckBold,
              color: 'success'
            }
          }
        }
      ],
      selected: null
    }
  },
  computed: {
  },
  methods: {
    add () {
      const { FlagState } = this.$FeathersVuex.api
      this.selected = new FlagState({ })
      this.ddo = true
    },
    select (item) {
      this.selected = item
      this.ddo = true
    }
  },
  setup(props, context) {
    const { FlagState } = context.root.$FeathersVuex.api

    const acbParams = computed(() => {
      return {
        query: {
          $sort: { enabled: -1, name: 1 },
          $limit: 500
        }
      }
    })

    const { items } = useFind({ model: FlagState, params: acbParams })

    return {
      items
    }
  }
}
</script>
