<template>
  <v-container fluid class="px-4 pb-16">

    <FilterBar
      @change="changeFilter"
    />

    <v-divider class="my-3"/>

    <DataTable
      :cols="cols"
      :data="items"
      @selectOne="selectOne"
    />

    <v-divider class="my-3"/>

    <FeathersVuexPagination v-model="pagination" :latest-query="latestQuery">
      <template #default="{ currentPage, pageCount, toStart, toEnd, toPage, next, prev, canNext, canPrev }">
        <div
          v-if="pageCount > 1"
          class="d-flex justify-space-between"
        >
          <div class="d-flex align-center primary--text text-subtitle-1 ">
            <div class="px-2 mr-3">{{ paginationString }}</div>
          </div>
          <Pagination
            v-model="currentPage"
            :length="pageCount"
            :total-visible="9"
            @input="toPage"
          />
        </div>
      </template>
    </FeathersVuexPagination>
  </v-container>
</template>

<script>
import { useFind } from 'feathers-vuex'
import { computed, ref } from '@vue/composition-api'

import Pagination from '@/components/UI/pagination'
import FilterBar from './filter-bar'

export default {
  props: {
    // items: {
    //   type: Array,
    //   default() { return [] }
    // }
  },
  components: {
    FilterBar,
    Pagination
  },
  data () {
    return {
      cols: [
        {
          field: 'userName',
          label: 'User',
          width: '10%',
          valueFunction: item => {
            return item.userName || item.detail.email
          }
        },
        {
          field: 'action',
          label: 'Action',
          width: '10%'
        },
        {
          field: 'detail',
          label: 'Detail',
          width: '10%',
          valueFunction: (item, field) => {
            const d = item[field]
            if (!d) return '-'
            return d.result
          }
        },
        {
          field: 'timestamp',
          label: 'Timestamp',
          width: '10%',
          valueFunction: (item, field) => {
            const d = item[field]
            if (!d) return '-'
            return new Date(d).toIACSformat(true)
          }
        }
      ]
    }
  },
  methods: {
    selectOne () {
    }
  },
  setup(props, context) {
    const { ActivityLog } = context.root.$FeathersVuex.api
    const { $options } = context.root
    const defaultLimit = 20

    const limit = ref(defaultLimit)
    const pagination = ref({
      $limit: limit,
      $skip: 0
    })

    const filter = ref({

    })

    const params = computed(() => {
      return {
        query: {
          ...filter.value,
          $select: [
            'userName',
            'action',
            'detail.result',
            'detail.email',
            'timestamp'
          ],
          $sort: { '_id': -1 },
          ...pagination.value,
        },
        qid: 'userActivityList',
        paginate: true
      }
    })

    const { items, latestQuery, paginationData } = useFind({
      model: ActivityLog,
      params,
      fetchParams: params
    })

    const total = computed(() => {
      return ((paginationData.value.userActivityList || {}).mostRecent || {}).total
    })

    const paginationString = computed(() =>{
      const t = total.value
      const r = $options.filters.pluralize('Result', t)
      const l = latestQuery.value && latestQuery.value.response ? latestQuery.value.response.limit : 0
      const s = latestQuery.value && latestQuery.value.response ? latestQuery.value.response.skip : 0
      return `${s} - ${Math.min(s+l, t)} of ${t} ${r}`
    })

    const changeFilter = (value) => {
      const timestamp = {}
      filter.value = {}
      if (value.user) {
        filter.value.user = value.user
      }
      if (value.from) {
        timestamp.$gte = value.from
      }
      if (value.to) {
        timestamp.$lte = (new Date(value.to)).valueOf() + 86400000 // one day
      }
      if (value.from || value.to){
        filter.value.timestamp = timestamp
      }
    }

    return {
      changeFilter,
      items,
      latestQuery,
      limit,
      pagination,
      paginationData,
      paginationString,
      total,

      filter
    }
  }
}
</script>
