import { mdiLock, mdiCheckBold } from "@mdi/js"

const enabled =  {
  align: 'center',
  field: 'enabled',
  label: '',
  width: '1%',
  type: Boolean,
  icons: {
    false: {
      icon: mdiLock,
      color: 'error'
    }
  }
}
const role = {
  field: 'role',
  label: 'Role',
  width: '25%'
}

const tabs = [
  {
    label: 'Op. Center',
    count: 44,
    type: 'oc',
    cols: [
      {
        field: 'name',
        label: 'Name',
        width: '25%'
      },
      role,
      {
        field: 'email',
        label: 'Email',
        width: '25%'
      },
      enabled
    ]
  },
  {
    label: 'Auditor',
    type: 'ac',
    cols: [
      {
        field: 'name',
        label: 'Name',
        width: '20%'
      },
      {
        field: 'assignedTo',
        label: 'Class Societies',
        width: '45%',
        valueFunction: item => {
          return item.assignedTo
            ? item.assignedTo.reduce((a, cs) => { a.push(cs.value); return a }, []).join(', ')
            : ''
        }
      },
      {
        field: 'email',
        label: 'Email',
        width: '20%'
      },
      {
        align: 'center',
        field: 'canCloseFindings',
        label: 'Can Close Findings',
        width: '15%',
        type: Boolean,
        icons: {
          true: {
            icon: mdiCheckBold,
            color: 'success'
          }
        }
      },
      enabled
    ]
  },
  {
    label: 'Class Society',
    type: 'cs',
    cols: [
      {
        field: 'name',
        label: 'Name',
        width: '25%'
      },
      {
        field: 'cs',
        label: 'Class Society',
        width: '25%',
        valueFunction: item => {
          return item.cs ? item.cs.name : ''
        }
      },
      role,
      {
        field: 'email',
        label: 'Email',
        width: '25%'
      },
      enabled
    ]
  },
  {
    label: 'Flag Admin.',
    type: 'fs',
    cols: [
      {
        field: 'name',
        label: 'Name',
        width: '25%'
      },
      {
        field: 'fs',
        label: 'Flag State',
        width: '25%',
        valueFunction: item => {
          return item.fs ? item.fs.name : ''
        }
      },
      role,
      {
        field: 'email',
        label: 'Email',
        width: '25%'
      },
      enabled
    ]
  },
  {
    label: 'External Party',
    type: 'ob',
    cols: [
      {
        field: 'name',
        label: 'Name',
        width: '25%'
      },
      role,
      {
        field: 'email',
        label: 'Email',
        width: '25%'
      },
      enabled
    ]
  },
  {
    label: 'Advanced Search',
    type: 'as',
    cols: [
      {
        field: 'name',
        label: 'Name',
        width: '25%'
      },
      role,
      {
        field: 'email',
        label: 'Email',
        width: '25%'
      },
      enabled
    ]
  },
]

export { tabs }
