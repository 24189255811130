<template>
  <v-container class="px-9 mt-4">
    <v-form
      ref="form"
    >
      <FormGroupInput
        field="name"
        hide-details="auto"
        :rules="['required']"
        :value="item.name"
        :readonly="Boolean(item._id)"
        :disabled="Boolean(item._id)"
        @change="change"
        @input="input"
      >
        Name
      </FormGroupInput>
      <FormGroupSwitch
        :value="item.enabled"
        field="enabled"
        @change="change"
      >
        Enabled
      </FormGroupSwitch>
      <v-row justify="end" class="mt-4">
        <v-col cols="auto">
          <v-btn
            v-if="!item._id"
            depressed
            color="success"
            :disabled="!canCreate"
            @click="create"
          >
            Save
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import FormGroupSwitch from '@/components/UI/forms/form-group/switch'
import FormGroupInput from '@/components/UI/forms/form-group/input'

export default {
  props: {
    item: {
      type: Object,
      default () { return {} }
    }
  },
  components: {
    FormGroupSwitch,
    FormGroupInput,
  },
  computed: {
    canCreate () {
      return this.item.name && this.item.name.trim()
    }
  },
  methods: {
    change({ field, value }) {
      if (value instanceof String) {
        value = (value || '').trim()
      }
      if (this.item._id) {
        this.$store.dispatch('flag-states/patch', [this.item._id, { [field]: value }])
      } else {
        this.item[field] = value
      }
    },
    create () {
      this.item.create().then(() => {}, this.raiseError)
    },
    async raiseError (error) {
      let message = error.message
      alert(message)
    },
    input({ field, value }) {
      if (!this.item._id) {
        if (value instanceof String) {
          value = (value || '').trim()
        }
        this.item[field] = value
      }
    }
  }
}
</script>
