var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"px-4 pb-16",attrs:{"fluid":""}},[_c('FilterBar',{on:{"change":_vm.changeFilter}}),_c('v-divider',{staticClass:"my-3"}),_c('DataTable',{attrs:{"cols":_vm.cols,"data":_vm.items},on:{"selectOne":_vm.view},scopedSlots:_vm._u([{key:"item.field",fn:function(ref){
var item = ref.item;
return [_c('div',{domProps:{"innerHTML":_vm._s(_vm.extract(item.data).field)}})]}},{key:"item.from",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-truncate red--text",domProps:{"innerHTML":_vm._s(_vm.extract(item.data).from)}})]}},{key:"item.to",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-truncate green--text",domProps:{"innerHTML":_vm._s(_vm.extract(item.data).to)}})]}}])}),_c('v-divider',{staticClass:"my-3"}),_c('FeathersVuexPagination',{attrs:{"latest-query":_vm.latestQuery},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var currentPage = ref.currentPage;
var pageCount = ref.pageCount;
var toStart = ref.toStart;
var toEnd = ref.toEnd;
var toPage = ref.toPage;
var next = ref.next;
var prev = ref.prev;
var canNext = ref.canNext;
var canPrev = ref.canPrev;
return [(pageCount > 1)?_c('div',{staticClass:"d-flex justify-space-between"},[_c('div',{staticClass:"d-flex align-center primary--text text-subtitle-1 "},[_c('div',{staticClass:"px-2 mr-3"},[_vm._v(_vm._s(_vm.paginationString))])]),_c('Pagination',{attrs:{"length":pageCount,"total-visible":9},on:{"input":toPage},model:{value:(currentPage),callback:function ($$v) {currentPage=$$v},expression:"currentPage"}})],1):_vm._e()]}}]),model:{value:(_vm.pagination),callback:function ($$v) {_vm.pagination=$$v},expression:"pagination"}}),_c('FindingOverlay',{attrs:{"idkey":"recent-changes","finding":_vm.selected},on:{"close":function($event){_vm.selected = null}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }