<template>
  <v-container fluid class="px-4 pb-16">

    <Confirm ref="confirm" />
    <AddNewDialog ref="addNewDialog" />

    <v-alert
      type="info"
      text
      :icon="false"
    >
      <div class="text-h6 mt-n1">
        Report Parameters &ndash; Important Information
      </div>
      <div class="text-subtitle-2">
        <br />
        <ul>
          <li>This page allows you to add and update the values of the <b>selection boxes</b> in the <b>Auditor's Finding Report form</b>.</li>
          <li>Changing these values <b>will not affect data</b> in open findings, archived findings, findings in the Inbox, rejected findings or draft findings on Auditor's own systems.</li>
          <li>Auditors will not see changes immediately.</li>
          <li>To see changes, Auditors need to reload / refresh.</li>
        </ul>
      </div>
    </v-alert>
    <v-row>
      <v-col>
        <div class="d-flex flex-grow-1 align-center px-2">
          <b>Please select the value list to edit:</b>
          <div
            class="pl-8"
            style="width:300px"
          >
            <Select
              field="field"
              label="Choose..."
              required
              :items="fields"
              :value="selectedField"
              @change="selectField"
            />
          </div>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <DataTable
          v-if="selectedField"
          :cols="cols"
          :data="tableData"
          :draggable="true"
          @selectOne="selectValue"
          @sortEnd='sortEnd'
        >
          <template v-slot:item.actions="{ item }">
            <v-btn
              text
              small
              color="error"
              @click.stop="remove(item)"
            >delete</v-btn>
          </template>
        </DataTable>
        <v-divider class="mb-4" />
        <v-btn
          v-if="selectedField"
          depressed
          color="success"
          @click="addNew"
        >
          Add new value
        </v-btn>
      </v-col>
    </v-row>

    <DocumentDrawer
      :open="ddo"
      :title="ddt"
      :width="640"
      :stateless="false"
      @close="ddo=false"
    >
      <template v-slot:default>
        <Detail
          v-if="selectedValue"
          :item="selectedValue"
          @close="ddo=false"
        />
      </template>
    </DocumentDrawer>
  </v-container>
</template>

<script>
import { mdiAlert } from "@mdi/js"

import { useFind } from 'feathers-vuex'
import { computed, ref } from '@vue/composition-api'
import { dictionary } from '@/models/finding'

import DocumentDrawer from '@/components/UI/document-drawer'
import Select from '@/components/UI/forms/select'

import AddNewDialog from './add-new-dialog'
import Detail from './detail'

export default {
  name: 'ReportParameters',
  components: { AddNewDialog, Detail, DocumentDrawer, Select },
  mounted () {
    this.$emit('loaded')
  },
  computed: {
    isOnline () { return this.$store.state.socket }
  },
  setup (props, context) {

    const { $store, $FeathersVuex } = context.root

    const editable = [
      'acbAuditType',
      'acbVCAType',
      'acbIQMSR',
      'acbPR',
      'acbSystem',
      'acbTechnical',
      'acbFinding_Cat'
    ]

    const ddo = ref(false)
    const ddt = computed(() => {
      if (!selectedField.value) return
      return dictionary[selectedField.value].longname
    })

    const selectedField = ref(null)
    const selectedValue = ref(null)

    const cols = computed(() => {
      const dblCol = selectedField.value === 'acbIQMSR'
      const c = [
        {
          field: 'value',
          label: 'Value',
          valueFunction: value => {
            return value.value || value
          },
          width: dblCol ? '70%' : '90%'
        }
      ]
      if (dblCol) {
        c.push ({
          field: 'ro',
          label: dictionary['acbRO_Code'].longname,
          width: '20%'
        })
      }
      c.push({
        field: 'actions',
        label: '',
        width: '10%'
      })
      return c
    })

    const fields = computed (() => {
      return editable.sort()
              .map(i => ({
                  text: dictionary[i].longname,
                  value: i
              }))
    })

    const tableData = computed(() => {
      const valueList = selectedField.value
        ? items.value.filter(i => i.field === selectedField.value)[0]
        : []
      return valueList.values
    })


    const addNewDialog = ref(null)
    const addNew = async () => {
      const result = await addNewDialog.value.open(selectedField.value)

      if (result) {
        const { _id } = items.value.filter(i => i.field === selectedField.value)[0]

        if (selectedField.value === 'acbIQMSR'){
          $store.dispatch('value-lists/patch', [_id, { $push: { values: {
            value: result.value,
            ro: result.ro
          } } }])
        } else{
          $store.dispatch('value-lists/patch', [_id, { $push: { values: result.value } }])
        }
      }
    }

    const confirm = ref(null)
    const remove = async item => {
      const result = await confirm.value.open(
        'Delete this parameter option?',
        'This cannot be undone ?',
        {
          acceptColor: 'red darken-4',
          acceptLabel: 'Discard',
          cancelLabel: 'Keep',
          color: 'red darken-4',
          icon: mdiAlert,
          width: 410
        }
      )
      if (result) {
        const { _id } = items.value.filter(i => i.field === selectedField.value)[0]
        $store.dispatch('value-lists/patch', [_id, { $pull: { values: item } }])
      }
    }

    const selectField = ({ value }) => {
      selectedField.value = value.value
      selectedValue.value = null
    }

    const selectValue = value => {
      const index = tableData.value.indexOf(value)
      const valueList = items.value.filter(i => i.field === selectedField.value)[0]
      selectedValue.value = {
        index,
        field: selectedField,
        list: valueList,
        value
      }
      ddo.value = true
    }

    const sortEnd = event => {
      const { _id, values } = items.value.filter(i => i.field === selectedField.value)[0]
      const nvals = [ ...values]
      const from = event.oldIndex
      const to = event.newIndex

      nvals.splice(to, 0, nvals.splice(from, 1)[0])

      setTimeout(() => {
        $store.dispatch('value-lists/patch', [_id, { values: nvals }])
      }, 100)
    }

    /*
      fetch values for selection boxes on the
      auditor's new finding / report form.
    */
    const { items } = useFind({
      model: $FeathersVuex.api.ValueList,
      params: {query: {}}
    })

    return {
      addNew,
      addNewDialog,
      cols,
      confirm,
      ddo,
      ddt,
      remove,
      fields,
      items,
      selectedField,
      selectField,
      selectedValue,
      selectValue,
      sortEnd,
      tableData
    }
  }
}
</script>
