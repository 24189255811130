<template>
  <v-container fluid class="px-4 pb-16">

    <FilterBar
      @change="changeFilter"
    />

    <v-divider class="my-3"/>

    <DataTable
      :cols="cols"
      :data="items"
      @selectOne="view"
    >
      <template v-slot:item.field="{ item }">
        <div v-html="extract(item.data).field" />
      </template>
      <template v-slot:item.from="{ item }">
        <div class="text-truncate red--text" v-html="extract(item.data).from" />
      </template>
      <template v-slot:item.to="{ item }">
        <div class="text-truncate green--text" v-html="extract(item.data).to" />
      </template>
    </DataTable>

    <v-divider class="my-3"/>

    <FeathersVuexPagination v-model="pagination" :latest-query="latestQuery">
      <template #default="{ currentPage, pageCount, toStart, toEnd, toPage, next, prev, canNext, canPrev }">
        <div
          v-if="pageCount > 1"
          class="d-flex justify-space-between"
        >
          <div class="d-flex align-center primary--text text-subtitle-1 ">
            <div class="px-2 mr-3">{{ paginationString }}</div>
          </div>
          <Pagination
            v-model="currentPage"
            :length="pageCount"
            :total-visible="9"
            @input="toPage"
          />
        </div>
      </template>
    </FeathersVuexPagination>

    <FindingOverlay
      idkey="recent-changes"
      :finding="selected"
      @close="selected = null"
    />

  </v-container>
</template>

<script>
import { useFind } from 'feathers-vuex'
import { computed, ref } from '@vue/composition-api'
import { dictionary } from '@/models/finding'

import FindingOverlay from '@/components/shared/finding-overlay'
import Pagination from '@/components/UI/pagination'
import FilterBar from './filter-bar'

export default {
  props: {},
  components: {
    FindingOverlay,
    FilterBar,
    Pagination,
  },
  data () {
    return {
      cols: [
        {
          field: 'userName',
          label: 'User',
          width: '10%',
          valueFunction: item => {
            return item.userName
          }
        },
        {
          field: 'kind',
          label: 'Kind',
          width: '5%'
        },
        {
          field: 'finding',
          label: 'Finding',
          width: '15%',
          valueFunction: (item, field) => {
            return (item[field].acbFindingFile || '').replace('.xml','')
          }
        },
        {
          field: 'field',
          label: 'Field',
          truncate: true,
          width: '5%',
        },
        {
          field: 'from',
          label: 'From',
          truncate: true
        },
        {
          field: 'to',
          label: 'To',
          truncate: true
        },
        {
          field: 'timestamp',
          label: 'Timestamp',
          width: '10%',
          valueFunction: (item, field) => {
            const d = item[field]
            if (!d) return '-'
            return new Date(d).toIACSformat(true)
          }
        }
      ]
    }
  },
  methods: {
    extract (itemData) {
      if(!itemData) return {}
      const oldValue = itemData.oldValue
      const trimmed = {
        ...itemData,
        acbFindingFile: undefined,
        oldValue: undefined
      }
      delete trimmed.acbFindingFile
      delete trimmed.oldValue
      const field = Object.keys(trimmed)[0]
      const newValue = trimmed[field]
      return {
        field: dictionary[field].shortname,
        from: this.purify(oldValue) || '-',
        to: this.purify(newValue) || '-'
      }
    },
    purify (value) {
      const type = typeof value
      if (type === 'boolean'){
        return value ? 'Yes' : 'No'
      }
      if (!value) return
      // might be datelike
      if (value.match('T00:00:00.000Z')){
        return new Date(value.replace('T00:00:00.000Z', '')).toIACSformat()
      }
      // or might be datelike ;)
      if (value.match(/\d{4}-\d{2}-\d{2}/)){
        return new Date(value).toIACSformat()
      }
      if (type === 'string'){
        return value
      }
      return value
    }
  },
  setup(props, context) {
    const { ChangeLog } = context.root.$FeathersVuex.api
    const { $options, $store } = context.root
    const defaultLimit = 20

    const selected = ref(null)

    const limit = ref(defaultLimit)
    const pagination = ref({
      $limit: limit,
      $skip: 0
    })

    const filter = ref({})

    const params = computed(() => {
      return {
        query: {
          ...filter.value,
          $select: [
            'user',
            'userName',
            'data',
            'kind',
            'finding',
            'timestamp'
          ],
          $sort: { '_id': -1 },
          ...pagination.value,
        },
        qid: 'userChangeLog',
        // paginate: true
      }
    })

    const { items, latestQuery, paginationData } = useFind({
      model: ChangeLog,
      params,
      fetchParams: params
    })

    const total = computed(() => {
      return ((paginationData.value.userChangeLog || {}).mostRecent || {}).total
    })

    const paginationString = computed(() =>{
      const t = total.value
      const r = $options.filters.pluralize('Result', t)
      const l = latestQuery.value && latestQuery.value.response ? latestQuery.value.response.limit : 0
      const s = latestQuery.value && latestQuery.value.response ? latestQuery.value.response.skip : 0
      return `${s} - ${Math.min(s+l, t)} of ${t} ${r}`
    })

    const changeFilter = (value) => {
      const timestamp = {}
      filter.value = {}
      if (value.user) {
        filter.value.user = value.user
      }
      if (value.kind) {
        filter.value.kind = value.kind
      }
      if (value.from) {
        timestamp.$gte = value.from
      }
      if (value.to) {
        timestamp.$lte = new Date((new Date(value.to)).valueOf() + 86400000).toISOString().substr(0,10) // one day
      }
      if (value.from || value.to){
        filter.value.timestamp = timestamp
      }
    }

    // const close = () => {
    //   editing.value = false
    //   ddo.value = false
    // }

    const view = async i => {
      const result = await $store.dispatch('findings/get', i.finding._id )
      selected.value = result
    }

    return {
      changeFilter,
      filter,
      items,
      latestQuery,
      limit,
      pagination,
      paginationData,
      paginationString,
      selected,
      total,
      view
    }
  }
}
</script>
