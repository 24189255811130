<template>
  <v-row
    align="center"
    dense
    no-gutters
  >
    <PageSubtitle
      :icon="false"
      title="Message History"
    />

    <v-col cols="12" class="mb-3">
      <List
        v-if="items.length"
        :items="items"
        @view="view"
      />
      <Message v-else />
    </v-col>

    <DocumentDrawer
      hide-print
      :open="documentDrawerOpen"
      :title="documentDrawerTitle"
      :width="960"
      :stateless="false"
      @close="documentDrawerOpen=false"
    >
      <template
        v-slot:actionMenu
      >
        <ActionMenu
          v-if="selected"
          :items="actionMenuItems"
        />
      </template>
      <template
        v-slot:default
      >
        <Submission
          v-if="selected.item._id"
          idkey="message-history"
          :id="selected.item._id"
          :index="selected.index"
          @next="next"
          @prev="prev"
        />
      </template>
    </DocumentDrawer>
  </v-row>
</template>

<script>
import {
  mdiCheck,
  mdiClose,
  mdiPrinter,
  mdiHelpCircle,
  mdiAlert
} from "@mdi/js"

import { useFind } from 'feathers-vuex'
import { computed, reactive, ref } from '@vue/composition-api'

import ActionMenu from '@/components/UI/action-menu'
import DocumentDrawer from '@/components/UI/document-drawer'
import PageSubtitle from '@/components/UI/page-subtitle'
import Submission from '../shared/submission'

import List from '../shared/submission-list'
import Message from './message'

export default {
  components: {
    ActionMenu,
    DocumentDrawer,
    List,
    Message,
    PageSubtitle,
    Submission,
  },
  setup(props, context) {
    const { Submission } = context.root.$FeathersVuex.api

    const documentDrawerOpen = ref(false)
    const selected = reactive({ item: {}, index: null  })

    const params = computed(() => {
      return { query: { read: true, $sort: { '_id': -1 } } }
    })
    const fetchParams = computed(() => {
      return {
        query: {
          read: true,
          $select: [
            'message',
            'subject',
            'read',
            'uploads._id',
            'uploads.acbFindingFile',
            'uploads.metadata.accepted.date',
            'uploads.metadata.rejected.date'
          ],
          $sort: { '_id': -1 },
          $limit: 99
        },
      }
    })

    const { items } = useFind({
      model: Submission,
      params,
      fetchParams
    })

    const file = computed(() => {
      return selected.item.uploads[selected.index]
    })

    const documentDrawerTitle = computed(() => {
      return selected.item.subject
    })

    const actionMenuItems = computed(() => {
      if (selected.index === null) return []
      const metadata = file.value.metadata
      const a = {
          label: 'Accept and Import',
          icon: mdiCheck,
          color: "success",
          action: accept,
          disabled: Boolean(metadata.accepted)
        }
      const r = {
          label: 'Reject',
          icon: mdiClose,
          color: "red",
          action: reject,
          disabled: Boolean(metadata.rejected)
        }
      const p = {
          label: 'Print',
          icon: mdiPrinter,
          action: print
        }
      return (metadata.accepted || metadata.rejected)
        ? [p]
        : [a, r, null, p]
    })

    const view = ({ item, index }) => {
      selected.item = item
      selected.index = index
      documentDrawerOpen.value = true
    }

    const next = () => {
      selected.index += 1
    }

    const prev = () => {
      selected.index -= 1
    }

    // reference to the confirm dialog
    const confirm = ref(null)

    const accept = async () => {
      const result = await confirm.value.open(
        'Accept and Import this Finding?',
        `Are you sure you want to accept and import ${file.acbFindingFile} ?`,
        {
          acceptColor: 'primary',
          acceptLabel: 'Accept and Import',
          cancelLabel: 'Cancel',
          color: 'primary',
          icon: mdiHelpCircle,
          width: 410
        }
      )
      if (result) {
        const field = `uploads.${selected.index}.metadata.accepted`
        patch(field)
      }
    }

    const reject = async () => {
      const result = await confirm.value.open(
        'Reject this Finding?',
        `Are you sure you want to reject ${file.acbFindingFile} ?`,
        {
          acceptColor: 'red darken-4',
          acceptLabel: 'Reject',
          cancelLabel: 'Cancel',
          color: 'red darken-4',
          icon: mdiAlert,
          width: 410
        }
      )
      if (result) {
        const field = `uploads.${selected.index}.metadata.rejected`
        patch(field)
      }
    }

    const patch = field => {
      const value = {
        date: Date.now(),
        browser: {
          platform: navigator.platform,
          language: navigator.language,
          userAgent: navigator.userAgent
        }
      }
      selected.item.patch({ data: { [field]: value }})
    }

    const print = () => {
    }

    return {
      accept,
      actionMenuItems,
      confirm,
      documentDrawerOpen,
      documentDrawerTitle,
      file,
      items,
      next,
      prev,
      print,
      reject,
      selected,
      view
    }
  }
}
</script>
