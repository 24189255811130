<template>
  <v-row dense no-gutters>
    <v-col
      align-self="center"
      class="d-flex align-center"
      cols="3"
    >
      <!-- {{ users }} -->
      <label class="primary--text mr-4"> User: </label>
      <Select
        field="user"
        label="Please Choose"
        required
        :items="users"
        :value="value.user"
        @change="change"
      />
    </v-col>

    <v-col cols="1" />

    <v-col
      align-self="center"
      class="d-flex align-center"
      cols="5"
    >
      <label class="primary--text nowrap mx-4"> From Date: </label>
      <DatePicker
        field="from"
        :value="value.from"
        :today="true"
        :future="false"
        @change="change"
      />
      <label class="primary--text nowrap mx-4"> To Date: </label>
      <DatePicker
        field="to"
        :value="value.to"
        :today="true"
        :future="false"
        @change="change"
      />
    </v-col>
    <v-col
      align-self="center"
      cols="3"
      class="d-flex justify-end"
    >
      <v-btn
        color="error"
        text
        @click="clear"
      >
        Clear Query
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
import { useFind } from 'feathers-vuex'
import { computed } from '@vue/composition-api'

import DatePicker from '@/components/UI/date-picker'
import Select from '@/components/UI/forms/select'

export default {
  props: {},
  data () {
    return {
      value: {}
    }
  },
  components: {
    DatePicker,
    Select
  },
  methods: {
    change ({ field, value }) {
      if (field === 'user') {
        value = value.value
      }
      this.value = { ...this.value, [field]: value }
      this.$emit('change', this.value)
    },
    clear () {
      this.value = {}
      this.$emit('change', this.value)
    }
  },
  setup(props, context) {
    const { User } = context.root.$FeathersVuex.api

    const params = computed(() => {
      return {
        query: {
          $select: ['_id', 'name'],
          $sort: { name: 1 },
          $limit: 99
        },
      }
    })

    const { items } = useFind({ model: User, params })

    const users = computed(() => {
      return items.value.map(u => {
        return { text: u.name, value: u._id }
      })
    })

    // const select = item => { emit('select', item) }

    return {
      users
    }
  }
}
</script>
