<template>
  <v-container v-if="submission"
    class="px-6 mt-2"
    fluid
  >
    <v-row
      dense
      no-gutters
    >
      <v-col cols="12" class="mb-0">
        <div class="d-flex justify-space-between align-center">
          <div class="flex-grow-1">
            <div class="text-h4">
              {{ finding.acbFindingFile.replace(/_/g, ' ') }}
            </div>
          </div>
          <div
            v-if="submission.uploads.length-1"
            class="d-flex d-print-none align-center"
          >
            <div class="primary--text font-weight-bold mr-4">
              Finding {{ index + 1 }} of {{ submission.uploads.length }}
            </div>
            <v-btn
              class="mr-2"
              color="primary"
              dense
              depressed
              :disabled="index==0"
              @click="$emit('prev')"
            >
              <v-icon dark>
                {{ mdiChevronLeft }}
              </v-icon>
            </v-btn>
            <v-btn
              color="primary"
              dense
              depressed
              :disabled="index==submission.uploads.length-1"
              @click="$emit('next')"
            >
              <v-icon dark>
                {{ mdiChevronRight }}
              </v-icon>
            </v-btn>
          </div>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-alert
          v-if="accepted && accepted.date"
          dense
          text
          type="success"
          :icon="false"
        >
          Accepted by
          <b class="font-weight-black">{{ accepted.user.name }}</b>
          on {{ formatDate(accepted.date) }}
        </v-alert>
        <v-alert
          v-if="rejected && rejected.date"
          dense
          text
          type="error"
          :icon="false"
        >
          Rejected by
          <b class="font-weight-black">{{ rejected.user.name }}</b>
          on {{ formatDate(rejected.date) }}
          <br>
          Reason: {{ rejected.reason }}
        </v-alert>

        <v-divider class="" />
      </v-col>
    </v-row>
    <!-- _{{ isPending }}_ -->
    <!-- <hr /> -->
    <v-row
      dense
      no-gutters
    >
      <v-col class="">
        <Finding
          :finding="finding"
          :idkey="idkey"
        >
          <template v-slot:proposed><span></span></template>
          <template v-slot:cas><span></span></template>
          <template v-slot:closeddate><span></span></template>
          <template v-slot:closedby><span></span></template>
          <template v-slot:notes><span></span></template>
        </Finding>
      </v-col>
    </v-row>

  </v-container>
</template>

<style scoped>
  .v-btn{
    min-width: 38px !important;
    width: 38px !important;
  }
</style>

<script>
import {
  mdiChevronLeft,
  mdiChevronRight
} from "@mdi/js"

import { useGet } from 'feathers-vuex'
import { computed } from '@vue/composition-api'

import Finding from "@/components/shared/findings/single-view"

export default {
  components: {
    Finding
  },
  props: {
    id: {
      type: String,
      required: true,
      default: ''
    },
    idkey: {
      type: String,
      required: true,
      default: ''
    },
    index: {
      type: Number,
      default: 0
    }
  },
  data: () => ({
    mdiChevronLeft,
    mdiChevronRight
  }),
  setup(props, context) {
    const { Submission } = context.root.$FeathersVuex.api
    const id = computed(() => {
      return props.id
    })

    const { item: submission, isPending } = useGet({
      model: Submission,
      id
    })

    const formatDate = date => {
      return new Date(date).toIACSformat()
    }

    const message = computed(() => submission.value.message)
    const finding = computed(() => submission.value.uploads[props.index])

    const accepted = computed(() => finding.value.metadata.accepted)
    const rejected = computed(() => finding.value.metadata.rejected)

    return {
      accepted,
      finding,
      formatDate,
      isPending,
      message,
      rejected,
      submission,
    }
  }
}
</script>
