<template>
  <Ask
    ref="ask"
    :canAccept="Boolean(result.value)"
  >
    <InputText
      autofocus
      class="mb-2"
      field="value"
      :value="result.value"
      @change="change"
    />
    <template
      v-if="field === 'acbIQMSR'"
    >
      <InputText
        autofocus
        class="mb-2"
        field="ro"
        placeholder="RO Code"
        :value="result.ro"
        @change="change"
      />
    </template>
    <!-- _{{ field }}_ -->
  </Ask>
</template>

<script>
import { dictionary } from '@/models/finding'

import InputText from '@/components/UI/forms/input-text'

export default {
  components: {
    InputText
  },
  data () {
    return {
      field: null,
      result: {}
    }
  },
  computed: {
    longname () {
      if(!this.field) return ''
      return dictionary[this.field].longname
    }
  },
  methods: {
    change (value) {
      this.result = { ...this.result, [value.field]: value.value }
    },
    async open (field) {
      this.field = field
      this.result = {}
      const result = await this.$refs.ask.open(
        `New ${this.longname} option`,
        'Please enter a value',
        {
          cancelLabel: 'Cancel',
          acceptLabel: 'Add New',
          reverseActions: true,
          width: 460
        }
      )
      if (result) {
        return this.result
      }
    }
  }
}
</script>
