<template>
  <main class="fill-height">
    <NavBar
      navigation-link
      home-link
      @showNavigation="nav=!nav"
    />
    <NavDrawer
      :open="nav"
      @close="nav=false"
    />
    <v-main class="fill-height">
      <router-view></router-view>
    </v-main>
  </main>
</template>

<script>
import { routes } from '@/router/oc'
import NavBar from '@/components/UI/nav-bar'
import NavDrawer from '@/components/UI/nav-drawer'

export default {
  name: 'OCHome',
  components: {
    NavBar,
    NavDrawer
  },
  computed: {
    title() {
      return this.$route.meta.page ? this.$route.meta.page.title : ''
    },
    icon() {
      return this.$route.meta.menu ? this.$route.meta.menu.icon : ''
    }
  },
  data () {
    return {
      nav: false,
      item: null
    }
  },
  created() {
    /*
      Dynamically add routes applicable to this user type.
      (this only actually applies OC users, this might change.)
      In the case of a logout/login cycle, these routes will
      already be loaded, so skip to prevent console error in
      during development
    */
    if (!this.$route.name) {
      this.$router.addRoutes(routes)
    }
  },
  async mounted() {
    this.$emit('loaded')
  }
}
</script>
