<template>
  <v-container class="px-9 mt-4">
    <Alert ref="alert" />
    <v-form
      ref="form"
    >
      <v-alert
        v-if="user._id && !user.enabled"
        type="error"
      >
        <b>This user account is disabled</b>
      </v-alert>

      <FormGroupInput
        field="name"
        hide-details="auto"
        :rules="['required']"
        :value="user.name"
        :readonly="Boolean(user.type === 'ac' && user._id)"
        :disabled="Boolean(user.type === 'ac' && user._id)"
        @change="change"
        @input="input"
      >
        Name
      </FormGroupInput>

      <FormGroupInput
        v-if="user.type !== 'ac'"
        field="role"
        :value="user.role"
        @change="change"
        @input="input"
      >
        Role
      </FormGroupInput>

      <v-divider class="my-5" />

      <FormGroupSectionLabel>Login Details</FormGroupSectionLabel>
      <FormGroupInput
        field="email"
        hide-details="auto"
        type="email"
        :rules="['required', 'email']"
        :value="user.email"
        @change="change"
        @input="input"
      >
        Email
      </FormGroupInput>

      <Password
        v-if="!user._id"
        :newUser="!user._id"
        @change="change"
        @update="change"
      />

      <v-divider class="my-5" />

      <template
        v-if="isUser('ac') && user._id"
      >
        <FormGroupLabelledComponent label="Assigned To">
          <ClassSocietyList
            :items="user.assignedTo"
            @add="addCS"
            @remove="removeCS"
          />
        </FormGroupLabelledComponent>
        <v-divider class="my-5" />
        <FormGroupSwitch
          :value="user.canCloseFindings"
          field="canCloseFindings"
          @change="change"
        >Can Close Findings</FormGroupSwitch>
        <v-divider class="my-5" />
      </template>

      <template
        v-if="isUser('cs')"
      >
        <ClassSocietySelector
          field="cs"
          :value="user.cs"
          :label="'Please Choose'"
          @change="changeCS"
        >Class Society</ClassSocietySelector>
        <v-divider class="my-5" />
      </template>

      <template
        v-if="isUser('fs')"
      >
        <FlagStateSelector
          field="fs"
          :value="user.fs"
          :label="'Please Choose'"
          @change="changeFS"
        >Flag State</FlagStateSelector>
        <v-divider class="my-5" />
      </template>

      <!-- <template
        v-if="user._id"
      >
        <FormGroupSectionLabel>Contact Information</FormGroupSectionLabel>
        <FormGroupInput
          field="telephone"
          :value="user.telephone"
          @change="change"
        >
          Telephone
        </FormGroupInput> -->
        <!-- <FormGroupInput
          field="timezone"
          :value="user.timezone"
          @change="change"
        >
          Timezone
        </FormGroupInput> -->
      <!-- </template> -->

      <v-row justify="end" class="mt-4">
        <v-col cols="auto">
          <v-btn
            v-if="!user._id"
            depressed
            color="success"
            :disabled="!canCreate"
            @click="create"
          >
            Save New User
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import { mdiAlert } from "@mdi/js"

import FormGroupSwitch from '@/components/UI/forms/form-group/switch'
import FormGroupInput from '@/components/UI/forms/form-group/input'
import FormGroupLabelledComponent from '@/components/UI/forms/form-group/labelled-component'
import FormGroupSectionLabel from '@/components/UI/forms/form-group/section-label'

import ClassSocietyList from './class-society-list'
import ClassSocietySelector from './class-society-selector'
import FlagStateSelector from './flag-state-selector'
import Password from './password'

export default {
  props: {
    user: {
      type: Object,
      default () { return {} }
    }
  },
  components: {
    FormGroupSwitch,
    FormGroupInput,
    FormGroupLabelledComponent,
    FormGroupSectionLabel,
    ClassSocietyList,
    ClassSocietySelector,
    FlagStateSelector,
    Password,
  },
  // data () {
  //   return {
  //     rules: {
  //       email: value => Boolean(value.match(/@/)) || 'Not a valid email',
  //       required: value => !!value || 'Required.',
  //       min: value => value.length >= 8 || 'Minimum 8 characters',
  //       matchPassword: value => {
  //         return (value === this.user.password) || (`The passwords you entered do not match`)
  //       }
  //     }
  //   }
  // },
  computed: {
    isUser () {
      return type => this.user.type === type
    },
    canCreate () {
      const a = this.user.name
      const b = this.user.email
      const c = this.user.password
      const d = this.user.type === 'cs'
        ? this.user.cs && this.user.cs._id
        : true
      const e = this.user.type === 'fs'
        ? this.user.fs && this.user.fs._id
        : true
      return a && b && c && d && e
    }
  },
  methods: {
    change({ field, value }) {
      if (value instanceof String) {
        value = (value || '').trim()
      }
      if (field === 'email'){
        value = value.toLowerCase()
      }
      // if this is an exisiting user
      // update on change|blur
      if (this.user._id) {
        this.user.patch({ data : { [field]: value } }).then(() => {}, this.raiseError)
      } else {
        this.user[field] = value
      }
    },
    input({ field, value }) {
      // update on input / keypress if this is an exisiting user
      if (!this.user._id) {
        if (value instanceof String) {
          value = (value || '').trim()
        }
        if (field === 'email'){
          value = value.toLowerCase()
        }
        this.user[field] = value
      }
    },
    // add cs to assignedTo list
    addCS(id){
      const value = [...this.user.assignedTo, id]
      this.change({ field:'assignedTo', value })
    },
    // change cs for CS users
    changeCS({ field, value}) {
      this.change({ field, value })
    },
    // change fs for FS users
    changeFS({ field, value}) {
      this.change({ field, value })
    },
    // remove cs from assignedTo list
    removeCS(index){
      const value = [...this.user.assignedTo]
      value.splice(index, 1)
      this.change({ field:'assignedTo', value })
    },
    //peterwilliams@iacs.org.uk
    create () {
      this.user.create().then(() => {}, this.raiseError)
    },
    async raiseError (error) {
      let message = error.message
      switch(message){
        case 'Validation failed: name: Path `name` is required.':
          message = 'You must enter a user name.'
        break
        case 'Validation failed: email: Path `email` is required.':
          message = 'You must enter an email address.'
        break
        case 'email: value already exists.':
          message = 'This email is already in use.<br /><br />Please enter a different email address and try again.'
        break
      }
      setTimeout( async () => {
        await this.$refs.alert.open(
          'Error',
          message,
          {
            acceptColor: 'red darken-4',
            acceptLabel: 'OK',
            color: 'red darken-4',
            icon: mdiAlert,
            width: 460
          }
        )
      }, 100)
    }
  },
  watch: {
    user () {
      this.$refs.form.resetValidation()
    }
  }
}
</script>
