<template>
  <FormGroupSelect
    required
    :field="field"
    :items="cslist"
    :label="label"
    :value="value.name"
    @change="change"
  >Flag</FormGroupSelect>
</template>

<script>
import { useFind } from 'feathers-vuex'
import { computed } from '@vue/composition-api'
import FormGroupSelect from '@/components/UI/forms/form-group/select'

export default {
  props: {
    value: {
      type: Object,
      default() { return {} }
    },
    field: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    }
  },
  components: {
    FormGroupSelect
  },
  setup(props, context) {
    const { FlagState } = context.root.$FeathersVuex.api
    const { emit } = context

    const change = ({ field, value }) => {
      const cs = items.value.find(i => i.name === value)
      emit('change', { field, value:cs })
    }

    const params = computed(() => {
      return {
        query: {
          $select: ['name', '_id'],
          $sort: { name: 1 },
          $limit: 99
        },
      }
    })

    const { items } = useFind({
      model: FlagState,
      params
    })

    const cslist = computed(() => {
      return items.value.map(i => i.name)
    })

    return {
      change,
      cslist
    }
  }

}
</script>
