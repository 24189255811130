<template>
  <div class="mt-n2">
    <template
      v-for="(cs, i) in items"
    >
      <div
        :key="i"
        class="d-flex flex-column"
      >
        <v-divider class="my-1" v-if="i"/>
        <div class="d-flex align-center justify-space-between">
          {{ cs.name }}
          <v-btn
            icon
            color="red"
            small
            @click="remove(i)"
          >
            <v-icon small>
              {{ mdiClose }}
            </v-icon>
          </v-btn>
        </div>
      </div>
    </template>
    <div class="d-flex align-center mt-2">
      <Select
        label="Add Class Society"
        required
        :items="cslist"
        :value="newCS"
        @change="change"
      />
      <v-btn
        class="ml-5"
        color="primary"
        small
        depressed
        :disabled="!newCS"
        :ripple="false"
        @click="addCS"
      >
        Add
      </v-btn>
    </div>
  </div>
</template>

<script>
import { mdiClose } from "@mdi/js"

import { useFind } from 'feathers-vuex'
import { computed, ref } from '@vue/composition-api'
import Select from '@/components/UI/forms/select'

export default {
  props: {
    items: {
      type: Array,
      default() { return [] }
    }
  },
  data: () => ({ mdiClose }),
  components: {
    Select
  },
  methods: {
    remove (i) {
      this.$emit('remove', i)
    }
  },
  setup(props, context) {
    const { ClassSociety } = context.root.$FeathersVuex.api
    const { emit } = context

    const newCS = ref(null)

    // eslint-disable-next-line no-unused-vars
    const change = ({ field, value }) => {
      newCS.value = value
    }

    const addCS = () => {
      const id = items.value.find(i => i.name === newCS.value)._id
      newCS.value = ''
      emit('add', id)
    }

    const params = computed(() => {
      return {
        query: {
          $select: ['name', '_id'],
          $sort: { name: 1 },
          $limit: 99
        },
      }
    })

    const { items } = useFind({ model: ClassSociety, params })

    const cslist = computed(() => {
      // dont list cs we already belong too
      const assignedTo = props.items.map(i => i._id)
      return items.value
        .filter(i => assignedTo.indexOf(i._id) === -1 )
        .map(i => i.name)
    })

    return {
      addCS,
      change,
      cslist,
      newCS
    }
  }

}
</script>
