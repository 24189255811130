<template>
  <DataTable
    :cols="cols"
    :data="items"
    @selectOne="select"
  />
</template>

<script>

export default {
  props: {
    items: {
      type: Array,
      default() { return {} }
    }
  },
  data () {
    return {
      cols: [
        {
          field: '',
          label: 'File',
          truncate: true,
          width: '30%',
          valueFunction: item => {
            return item.finding.acbFindingFile
          }
        },
        {
          field: '',
          label: 'Date',
          width: '15%',
          valueFunction: item => {
            return new Date(item.metadata.rejected.date).toIACSformat()
          }
        },
        {
          field: '',
          label: 'Rejected By',
          truncate: true,
          width: '15%',
          valueFunction: item => {
            return item.metadata.rejected.user.name
          }
        },
        {
          field: '',
          label: 'Reason',
          width: '40%',
          valueFunction: item => {
            return item.metadata.rejected.reason
          }
        },
      ],
      selected: []
    }
  },
  methods: {
    select(selection) {
      this.$emit('view', selection)
    }
  }
}
</script>
