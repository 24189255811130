<template>
  <v-container class="px-9 mt-4">
    <v-form
      ref="form"
    >
      <template v-if="item.field === 'acbIQMSR'">
        <FormGroupLabelledComponent label="Value">
          <FormTextArea
            height="auto"
            field="value"
            :rows="1"
            :auto-grow="true"
            :no-resize="true"
            :value="item.value.value"
            @change="change"
          ></FormTextArea>
        </FormGroupLabelledComponent>
        <FormGroupLabelledComponent label="RO Code(s)">
          <FormTextArea
            height="auto"
            field="ro"
            :rows="1"
            :auto-grow="true"
            :no-resize="true"
            :value="item.value.ro"
            @change="change"
          ></FormTextArea>
        </FormGroupLabelledComponent>
      </template>

      <template v-else>
        <FormGroupLabelledComponent label="Value">
          <FormTextArea
            height="auto"
            field=""
            :rows="1"
            :auto-grow="true"
            :no-resize="true"
            :value="item.value"
            @change="change"
          ></FormTextArea>
        </FormGroupLabelledComponent>
      </template>
    </v-form>
  </v-container>
</template>

<script>
import FormGroupLabelledComponent from '@/components/UI/forms/form-group/labelled-component'
import FormTextArea from '@/components/UI/forms/textarea'

export default {
  props: {
    item: {
      type: Object,
      default () { return {} }
    }
  },
  components: {
    FormGroupLabelledComponent,
    FormTextArea
  },
  methods: {
    change({ field, value }) {
      if (!value) return
      if (!value.trim()) return

      const id = this.item.list._id
      const index = this.item.index

      if (this.item.field === 'acbIQMSR'){
        this.$store.dispatch('value-lists/patch', [
          id,
          {
            [`values.${index}.${field}`]: value
          }
        ])
      } else {
        this.$store.dispatch('value-lists/patch', [
          id,
          {
            [`values.${index}`]: value
          }
        ])
      }
    }
  }
}
</script>
