<template>
  <v-row align="center" no-gutter dense>
    <v-col cols="3" class="primary--text text-caption">
      <slot />
    </v-col>
    <v-col cols="9" class="">
      <v-btn
        color="primary"
        depressed
        @click="$emit('click')"
        :ripple="false"
      >
        {{ label }}
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: ''
    }
  }
}
</script>
