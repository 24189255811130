<template>
  <v-row align="center" no-gutter dense>
    <v-col cols="3" class="primary--text text-caption pt-2">
      <slot />
    </v-col>
    <v-col cols="auto" class="my-1">
      <v-switch
        v-model="on"
        class="my-0"
        hide-details
        inset
        :label="label || ( on ? 'Yes' : 'No')"
        :ripple="false"
      />
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    field: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    value: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    on: {
      get: function() { return this.value },
      set: function(value) {
        const field = this.field
        this.$emit('change', { field, value })
      }
    }
  }
}
</script>
